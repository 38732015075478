import React from 'react';

import {
  EmblaCarousel as Component,
  type Slide,
} from '@virginexperiencedays/components-v2/src/carousels/EmblaCarousel';
import type { PrismicLink } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import type { ImmersiveHeroImageProps as ImageProp } from '@virginexperiencedays/components-v2/src/pages/product/ImmersiveHeroImage';
import type { ImageObject } from '@virginexperiencedays/components-v2/src/utils/imageUtils';

export type ImagePropsSliceMachine = {
  image_mobile: ImageObject;
  image_tablet: ImageObject;
  image_desktop: ImageObject;
  cta_link: PrismicLink;
};

type ImmersiveHeroCarouselProps = {
  slice: {
    items: ImagePropsSliceMachine[];
  };
  context: {
    getPrismicLinkPath: (link: PrismicLink) => string;
  };
};

const MAX_ITEMS = 4;

const ImmersiveHeroCarousel = ({ slice, context }: ImmersiveHeroCarouselProps) => {
  const { items } = slice;
  const { getPrismicLinkPath } = context;

  if (items.length === 0) {
    console.warn('ImmersiveHeroCarousel: No items to display');
    return null;
  }

  const validItems = items.filter(
    (item) => item.image_mobile?.url && item.image_tablet?.url && item.image_desktop?.url,
  );

  if (validItems.length < items.length)
    console.warn('immersiveHeroCarousel: Some items are missing image url');

  const formattedSlides = validItems.slice(0, MAX_ITEMS).map((item) => {
    const ctaLink = getPrismicLinkPath(item.cta_link);
    const slide: Slide<ImageProp> = {
      imageMobile: item.image_mobile,
      imageTablet: item.image_tablet,
      imageDesktop: item.image_desktop,
      ctaLink,
      immersive: true, // Distinction for the EmblaCarousel
    };
    return slide;
  });

  return <Component slides={formattedSlides} options={{ loop: true, active: true }} autoplay />;
};

export default ImmersiveHeroCarousel;
