import { PageTitle as Component } from '@virginexperiencedays/components-v2/src/PageTitle';
import type { PrismicContext } from '../../types/context';

type PageTitleProps = {
  context: PrismicContext;
};

const PageTitle = ({ context }: PageTitleProps) => {
  const { mainContentId, pageDataTitle } = context;

  return <Component title={pageDataTitle} id={mainContentId} />;
};

export default PageTitle;
