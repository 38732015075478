import type { MouseEvent } from 'react';

import { cn } from '@virginexperiencedays/tailwind';
import { defaultHeroBannerBgImages, maskSquircle } from '@virginexperiencedays/cms/constants';

import { Button } from '../../layout/Button';
import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { BodyText } from '../../typography/BodyText';
import { VisualHeading } from '../../typography/VisualHeading';

import { Overtitle } from './Overtitle';

type HeroStyle01Props = {
  title: string;
  body: string;
  imageUrl: string;
  link: string;
  onClick: (e: MouseEvent<HTMLElement>) => void;
  ctaLabel: string;
  backgroundImage: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  className?: string;
  dataTestId?: string;
  overTitle?: string;
  imageIsPriority?: boolean;
  imageAlt?: string;
};

export const getBackgroundImages = (backgroundImage?: HeroStyle01Props['backgroundImage']) => {
  // I don't like it, refering to node env in UI component? @TODO: refactor UI should be agnostic
  const defaultBgImage =
    defaultHeroBannerBgImages?.[process.env.NODE_ENV] ?? defaultHeroBannerBgImages.production;

  // We inherit bg image overrides upward (e.g. if has no tablet override, but has mobile and desktop, tablet uses mobile)
  // Unless only one breakpoint override is uploaded, we will use it for all breakpoints
  // If no bg image overrides at all, will use the default bg images
  const mobile =
    backgroundImage?.mobile ||
    backgroundImage?.tablet ||
    backgroundImage?.desktop ||
    defaultBgImage.mobile;
  const tablet =
    backgroundImage?.tablet ||
    backgroundImage?.mobile ||
    backgroundImage?.desktop ||
    defaultBgImage.tablet;
  const desktop =
    backgroundImage?.desktop ||
    backgroundImage?.tablet ||
    backgroundImage?.mobile ||
    defaultBgImage.desktop;

  return {
    mobile,
    tablet,
    desktop,
  };
};

export const HeroStyle01 = ({
  className,
  title,
  body,
  overTitle,
  imageAlt,
  imageUrl,
  link,
  onClick,
  ctaLabel,
  backgroundImage,
  imageIsPriority = false,
  dataTestId = 'hero-style-01',
}: HeroStyle01Props) => {
  const hasBgImageOverride = !!(
    backgroundImage?.mobile ||
    backgroundImage?.tablet ||
    backgroundImage?.desktop
  );

  const bgImage = getBackgroundImages(backgroundImage);
  const mainImageMask = maskSquircle?.[process.env.NODE_ENV] ?? maskSquircle.production;

  return (
    <LinkWrap
      data-testid={dataTestId}
      className={cn(
        'text-grey-800 relative mx-auto flex max-w-[1280px] flex-col overflow-hidden rounded-lg sm:flex-row sm:items-center md:min-h-[400px] lg:min-h-[480px]',
        hasBgImageOverride && 'bg-[#ffedee]', // weird but its not in the theme?
        className
      )}
      href={link}
      onClick={onClick}
    >
      <picture className="absolute inset-0 z-[0] h-full w-full">
        <source srcSet={bgImage.desktop} media={`(min-width: 992px)`} />
        <source srcSet={bgImage.tablet} media={`(min-width: 576px)`} />

        {/* https://caniuse.com/?search=fetchpriority  fetch priority is for crawlers to improve LCP*/}
        {/* @ts-ignore */}
        <img
          className="h-full w-full object-cover object-center"
          src={bgImage.mobile}
          alt={title}
          width="1280"
          height="480"
          loading="eager"
          // @ts-ignore
          fetchPriority="high"
        />
      </picture>
      <div className="relative z-[1] shrink-0 p-4 sm:w-[35%] md:w-1/2 xl:w-2/5">
        {imageUrl && (
          <div className="relative mx-auto aspect-square w-full max-w-[272px] md:max-w-[320px] lg:max-w-[400px] xl:mr-0">
            <Image
              style={{
                WebkitMaskImage: `url(${mainImageMask})`,
                WebkitMaskSize: 'contain',
                WebkitMaskRepeat: 'no-repeat',
              }}
              className="image:object-cover image:object-center h-full"
              src={imageUrl}
              alt={imageAlt || title}
              priority={imageIsPriority}
              fill
            />
          </div>
        )}
      </div>
      <div className="relative z-[1] px-6 pb-10 pt-0 sm:px-6 sm:py-10 md:pr-10 lg:w-1/2 xl:m-auto xl:w-3/5 xl:max-w-[580px]">
        {overTitle && <Overtitle className="mb-4">{overTitle}</Overtitle>}
        {title && (
          <VisualHeading
            size="2"
            className="font-normal leading-none tracking-normal sm:text-4xl/none md:text-5xl/none md:-tracking-[0.01em] lg:text-[3.5rem] xl:text-[4rem]"
          >
            {title}
          </VisualHeading>
        )}
        {body && <BodyText className="mt-4 lg:text-xl/normal">{body}</BodyText>}
        {ctaLabel && (
          <Button
            className="mt-6 min-h-12 w-full border-0 px-10 py-4 sm:w-auto sm:min-w-[250px]"
            variant="primary"
            size="large"
          >
            {ctaLabel}
          </Button>
        )}
      </div>
    </LinkWrap>
  );
};
