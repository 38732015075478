import { cn } from '@virginexperiencedays/tailwind';
import { Container } from '../layout/Container';
import { RichTextWrap } from '../typography/RichTextWrap';

import type { RichTextField } from '@prismicio/types';

type Props = {
  /**
   * Page data H1
   */
  title?: RichTextField;
  /**
   * Main content id
   */
  id?: string;
};

export const PageTitle = ({ title, id }: Props) => {
  if (!title || !id) return null;

  return (
    <Container
      id={id}
      className={cn(
        'flex flex-row flex-nowrap items-center justify-center py-4 -mb-10',
        '[&_h1]:m-0 [&_h1]:text-center [&_h1]:font-inter [&_h1]:text-sm [&_h1]:leading-[1.4] [&_h1]:text-neutral-strong',
      )}
    >
      <RichTextWrap field={title} />
    </Container>
  );
};
