import { dispatchDynamicContentLoadedEvent, emitEvent } from '../../../apps/web/utils/events'; // <- side effect
import type { BasketApiResponse, BasketDataApiResponse } from './types';
import type { BasketItemFlags } from './utils/basketItemFlags';
import { buildQueryString } from './utils/buildQueryString';

const endpoint = process?.env?.NEXT_PUBLIC_HOME_LINK || '';

export const addToBasket = async (
  id: number,
  qty: number,
  actionEvents: () => void,
  dispatch: (action: { type: string; payload?: BasketApiResponse }) => void,
  flags?: BasketItemFlags[],
) => {
  if (!endpoint) throw new Error('Failed to update basket: missing NEXT_PUBLIC_HOME_LINK variable');

  const queryString = buildQueryString(id, qty, flags ?? []);

  const res = await updateBasket(
    `${endpoint.replace(/\/$/, '')}/basket/items/addJson`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: queryString,
    },
    actionEvents,
    dispatch,
  );
  return res;
};

const updateBasket = async (
  apiUrl: string,
  postConfig: RequestInit,
  actionEvents: () => void,
  dispatch: (action: { type: string; payload?: BasketApiResponse }) => void,
) => {
  try {
    const res = await fetch(apiUrl, postConfig);
    const json = await res.json();

    const {
      Status: { Success, Alert },
      Basket,
    } = json;

    if (Success && Basket) {
      dispatch?.({ type: 'UPDATE_BASKET_SUCCESS', payload: Basket });
      dispatchDynamicContentLoadedEvent('Update Basket Item');
      emitEvent('BasketUpdated', Basket);
      actionEvents?.();

      return Basket;
    }
    if (!Success && Alert?.AlertMessage) {
      console.error(`updateBasketItem error: ${Alert.AlertMessage}`);
    }
  } catch (e) {
    console.error(`updateBasketItem error: ${e}`);
  }
};

export const updateBasketItem = async (
  id: string | number,
  qty: number,
  actionEvents: () => void,
  dispatch: (action: { type: string; payload?: BasketApiResponse }) => void,
  shouldReturnFullBasket = false,
) => {
  if (!endpoint) throw new Error('Failed to update basket: missing NEXT_PUBLIC_HOME_LINK variable');

  const updatedBasket = await updateBasket(
    `${endpoint.replace(/\/$/, '')}/basket/items/${id}/setquantity`,
    {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `qty=${qty}`,
    },
    actionEvents,
    dispatch,
  );

  if (shouldReturnFullBasket) return updatedBasket;

  return updatedBasket.Items;
};

type HandleSetBasketPromoType = (
  apiUrl: string,
  promoCode: string,
) => Promise<{ success: boolean; message: string; data: BasketDataApiResponse | null }>;

export const handleSetBasketPromo: HandleSetBasketPromoType = async (apiUrl, promoCode) => {
  try {
    if (!apiUrl) throw new Error('Failed to fetch basket: missing endpoint variable');

    if (!promoCode) throw new Error('Failed to fetch basket: missing discount code');

    const endpoint = `${apiUrl.replace(/\/$/, '')}/basket/setpromocode`;
    const res = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `promoCode=${promoCode}`,
    });

    const json = (await res.json()) as BasketApiResponse;

    if (!json?.Status?.Success) throw new Error(json?.Status?.Alert?.AlertMessage);
    if (!json.Basket) throw new Error('Failed to fetch basket: missing basket data');

    return {
      success: true,
      message: 'Discount code applied successfully',
      data: json.Basket,
    };
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Unknown error',
      data: null,
    };
  }
};

type HandleClearBasketPromoType = (apiUrl: string) => Promise<{
  success: boolean;
  message: string;
  data: BasketDataApiResponse | null;
}>;

export const handleClearBasketPromo: HandleClearBasketPromoType = async (apiUrl) => {
  try {
    if (!apiUrl) throw new Error('Failed to fetch basket: missing endpoint variable');

    const endpoint = `${apiUrl.replace(/\/$/, '')}/basket/clearpromocode`;
    const res = await fetch(endpoint, { method: 'POST' });

    const json = (await res.json()) as BasketApiResponse;

    if (!json?.Status?.Success) throw new Error(json?.Status?.Alert?.AlertMessage);
    if (!json?.Basket) throw new Error('Failed to fetch basket: missing basket data');

    return {
      success: true,
      message: 'Discount code cleared successfully',
      data: json.Basket,
    };
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Unknown error',
      data: null,
    };
  }
};

export * from './types';
