// This file is generated by Slice Machine. Update with care!

import AccordionGroup from './AccordionGroup';
import Banner from './Banner';
import Button from './Button';
import CarouselStructure from './CarouselStructure';
import CarouselWithIntroSlot from './CarouselWithIntroSlot';
import CarouselWithTabs from './CarouselWithTabs';
import CategoryReviews from './CategoryReviews';
import ChunkyMessage from './ChunkyMessage';
import ColumnStructure from './ColumnStructure';
import CorporateLeadForm from './CorporateLeadForm';
import CustomImage from './CustomImage';
import CustomNuggets from './CustomNuggets';
import FaqAccordionGroup from './FaqAccordionGroup';
import FeaturedProductGrid from './FeaturedProductGrid';
import FeaturedVisualCards from './FeaturedVisualCards';
import GiftCardPriceSelector from './GiftCardPriceSelector';
import GiftCardSlider from './GiftCardSlider';
import GiftCardUsp from './GiftCardUsp';
import GridStructure from './GridStructure';
import HeroStyle01 from './HeroStyle01';
import HeroStyle02 from './HeroStyle02';
import HeroStyle03 from './HeroStyle03';
import HeroStyle04 from './HeroStyle04';
import HeroStyle05 from './HeroStyle05';
import HtmlEmbed from './HtmlEmbed';
import IconBanners from './IconBanners';
import ImageRow from './ImageRow';
import ImmersiveHeroCarousel from './ImmersiveHeroCarousel';
import ListOfLinks from './ListOfLinks';
import MegaNuggets from './MegaNuggets';
import Newsletter from './Newsletter';
import Nugget from './Nugget';
import PageTitle from './PageTitle';
import ProductGridSlice from './ProductGridSlice';
import PromoBanner from './PromoBanner';
import RichText from './RichText';
import Spacer from './Spacer';
import Title from './Title';
import UspGrid from './UspGrid';

export {
  AccordionGroup,
  Banner,
  Button,
  CarouselStructure,
  CarouselWithIntroSlot,
  CarouselWithTabs,
  CategoryReviews,
  ChunkyMessage,
  ColumnStructure,
  CorporateLeadForm,
  CustomImage,
  CustomNuggets,
  FaqAccordionGroup,
  FeaturedProductGrid,
  FeaturedVisualCards,
  GiftCardPriceSelector,
  GiftCardSlider,
  GiftCardUsp,
  GridStructure,
  HeroStyle01,
  HeroStyle02,
  HeroStyle03,
  HeroStyle04,
  HeroStyle05,
  HtmlEmbed,
  IconBanners,
  ImageRow,
  ImmersiveHeroCarousel,
  ListOfLinks,
  MegaNuggets,
  Newsletter,
  Nugget,
  PageTitle,
  ProductGridSlice,
  PromoBanner,
  RichText,
  Spacer,
  Title,
  UspGrid,
};

export const components = {
  accordion_group: AccordionGroup,
  banner: Banner,
  button: Button,
  carousel_structure: CarouselStructure,
  carousel_with_intro_slot: CarouselWithIntroSlot,
  carousel_with_tabs: CarouselWithTabs,
  category_reviews: CategoryReviews,
  chunky_message: ChunkyMessage,
  column_structure: ColumnStructure,
  corporate_lead_form: CorporateLeadForm,
  image_slice: CustomImage,
  custom_nuggets: CustomNuggets,
  faq_accordion_group: FaqAccordionGroup,
  featured_product_grid: FeaturedProductGrid,
  featured_visual_cards: FeaturedVisualCards,
  gift_card_price_selector: GiftCardPriceSelector,
  gift_card_slider: GiftCardSlider,
  gift_card_usp: GiftCardUsp,
  grid_structure: GridStructure,
  hero_style01: HeroStyle01,
  hero_style02: HeroStyle02,
  hero_style03: HeroStyle03,
  hero_style04: HeroStyle04,
  hero_style05: HeroStyle05,
  html_embed: HtmlEmbed,
  icon_banners: IconBanners,
  image_row: ImageRow,
  immersive_hero_carousel: ImmersiveHeroCarousel,
  list_of_links: ListOfLinks,
  mega_nuggets: MegaNuggets,
  newsletter: Newsletter,
  nugget: Nugget,
  page_title: PageTitle,
  product_grid: ProductGridSlice,
  promo_banner: PromoBanner,
  rich_text: RichText,
  spacer: Spacer,
  title: Title,
  usp_grid: UspGrid,
};
